import React from 'react'
import style from './style.module.scss'
import Light from 'components/colorLight'
import Dinamiza from 'components/colorDinamiza'
import arrow from 'images/arrow-thick.svg'
import AniLink from "gatsby-plugin-transition-link/AniLink";

class ItemPosition extends React.Component{
  render() {

    const { url, children } = this.props

    return(
      <div className={style.itemPosition}>
        <AniLink to={url} cover direction="up" bg={'#2E2E2F'}>
          {children}
          <h4>Scopri di più e candidati <img src={arrow} /></h4>
        </AniLink>
      </div>
    )
  }
}

export default ItemPosition
