import React from "react";
import style from "scss/pages/contact.module.scss";
import Form from "components/form";


class FormContactCareers extends React.Component{

  render() {

    const { position } = this.props

    const config = {
      klassName: 'form-contact-page',
      successMessage: 'Grazie per averci contattato.',
      errorMessage: 'Uno o più dati sono mancanti.',
      fields:{
        firstName: '',
        lastName: '',
        email: '',
        msg: '',
        position: ''
      },
      fieldsConfig:  [
        { id: 1, label: '', fieldName: 'firstName', type: 'text',placeholder:'Nome', isRequired: true , className:'first-name-field'},
        { id: 2, label: '', fieldName: 'lastName', type: 'text', placeholder: 'Cognome', isRequired: true , className:'last-name-field'},
        { id: 3, label: '', fieldName: 'email', type: 'email', placeholder: ' eMail', isRequired: true , className:'email-field'},
        { id: 4, label: '', fieldName: 'msg', type: 'textarea',placeholder:'Messaggio', isRequired: true , className:'message-field'},
        { id: 5, label: 'Carica', fieldName: 'file', type: 'file',placeholder:'', isRequired: true , className:'message-field'},
        { id: 6, label: '', fieldName: 'position', type: 'hidden', name: position, placeholder:'', isRequired: true , className:'message-field'},
        { id: 7, label: 'Consenso al trattamento di dati personali ai sensi del D. LGS. N. 196/03 e del REG. UE 2016/679 - Informativa candidati', fieldName: 'policy', type: 'radio', placeholder:'Write something.....', name1: 'Accetto', isRequired: true , className:'message-field', link: 'Consulta l\'informativa'},
        { id: 8, label: 'Consenso al trattamento di dati personali a fini di marketing.', fieldName: 'marketing', type: 'radio', placeholder:'', name1: 'Accetto', name2: 'Rifiuto', isRequired: true , className:'message-field'},
      ]
    }

    return(
      <div className={style.form}>
        <Form config={config} />
      </div>
    )
  }

}

export default FormContactCareers
