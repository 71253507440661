import React from 'react'
import Layout from 'components/layout'
import Meta from 'components/meta'
import { siteMetadata } from '../../../gatsby-config'
import Lines from 'components/lines'
import Dinamiza from 'components/colorDinamiza'
import Light from 'components/colorLight'
import style from 'scss/pages/posizioniAperte.module.scss'
import ItemPosition from 'components/itemPosition'
import FormContactCareers from "components/formContactCareers";

class WordpressDeveloper extends React.Component{
  render() {

    const { location, data } = this.props

    return(
      <div ref={this.page} className={style.page}>
        <Layout location={location}>
          <Meta site={siteMetadata} title="Wordpress Developer" description={'Entra a far parte del Team Dinamiza: la web agency a Venezia specializzata in ecommerce, applicazioni e siti web innovativi e di design.'}/>

          <Lines />

          <div className={`container-fluid ${style.page__content}`}>
            <div className={`row`}>
              <div className={`offset-md-2 col-md-2`}>
                <p className={`has-color-primary-negative ${style.page__content__claimLeft}`}><Light>Wordpress Developer</Light></p>
              </div>
              <div className={`offset-md-1 col-md-7 no-padding ${style.page__content__fullScreenSlide}`}>
                <div>
                  <h3>Cerchiamo un Wordpress Developer</h3>

                  <p>Stiamo cercando uno sviluppatore da includere nel nostro team. Cerchiamo una persona di talento e fortemente motivata, interessata al mondo digitale a 360 gradi, disposta a portare le proprie competenze all'interno del team e ad acquisire ulteriori conoscenze e competenze legate allo sviluppo su wordpress.</p>

                  <p>Posti Vacanti: 1</p>

                  <p>Responsabilità</p>
                  <ul className="lista_generica lista_competenze">
                    <li>Possibilità di collaborare in team alla realizzazione di siti web principalmente in WordPress e Shopify</li>
                  </ul>
                  <p>Skills necessarie:</p>
                  <ul className="lista_generica lista_competenze">
                    <li>Familiarità con Figma</li>
                    <li>Ottima Conoscenza SASS</li>
                    <li>Ottima Conoscenza di sistemi di versioning (git)</li>
                    <li>Buona conoscenza linguaggio Php e MySql/Maria DB</li>
                    <li>Esperienza su WordPress: temi custom e Woocommerce</li>
                    <li>Ottima conoscenza HTML5, CSS3, SCSS e JavaScript</li>
                    <li>Capacità di sviluppare design responsive con Bootstrap o altri framework</li>
                  </ul>
                  <p>Contratto di lavoro: Valutiamo sia assunzioni che collaborazioni in PIVA</p>
                  <p>Modalità di lavoro: Ibrido o remoto</p>
                  <p>Viene richiesta un esperienza anche minima ma significativa su progetti svolti.</p>


                  <h4 className={'title'}>Inviaci il curriculum</h4>
                  <FormContactCareers position={'RIF.56 - Front end Developer'} />

                </div>
              </div>
            </div>
            <div className={`offset-md-5 col-md-7 no-padding ${style.page__content__fullScreenSlide__arrow}`}></div>
          </div>
        </Layout>
      </div>
    )
  }
}

export default WordpressDeveloper
